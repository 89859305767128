<template>
  <div>
    <div v-if="asWidget" class="
      ds-display-flex ds-flex-direction-column ds-md-flex-direction-row
      ds-md-justify-content-space-between
      ds-align-items-flex-start
      ds-md-align-items-center
      ds-margin-bottom-48">
      <h2 class="ds-heading" v-text="careerHeadline" />
      <div class="ds-margin-top-24 ds-md-margin-top-0">
        <strong>{{ localJobs.totalAmount || 0 }}</strong> {{ labels.jobOpenings }}
      </div>
    </div>
    <div v-if="isPending" class="ds-display-flex ds-width-full ds-align-items-center ds-justify-content-center">
      <Loading :size="32" />
    </div>
    <div v-else-if="!filteredVacanciesLinks.length" class="ds-text-align-center">
      <h3 class="ds-heading-small" v-text="labels.noResults" />
      <p class="ds-margin-top-12" v-text="labels.checkBack" />
    </div>
    <ul v-else>
      <li v-for="(item, index) in filteredVacanciesLinks" :key="`vacancies-list-item-${index}`">
        <CareersVacanciesListItem :item="item" :as-widget="asWidget" :compact="compact"
          @item-clicked="handleItemClicked" />
        <DsDivider />
      </li>
    </ul>

    <DsLink v-if="asWidget" :url="vacanciesUrl" :content="labels.seeAllVacancies" type="button" class="ds-margin-top-48"
      nuxt />
    <span ref="endOfPage" />
  </div>
</template>q

<script setup>
import { DsDivider, DsLink } from 'awds'
const JOBS_AMOUNT = 5
const { localeGlobalContentData } = useCareersGlobalData()
const { getQueryParamsForCareerType, getQueryParamsForCity, JobFilter } = useGreenHouseUtils()
const route = useRoute()

const props = defineProps({
  asWidget: {
    type: Boolean,
    default: false
  },
  items: {
    type: Array,
    default: () => null
  },
  // totalAmount: {
  //   type: Number,
  //   default: 0
  // },
  compact: {
    type: Boolean,
    default: false
  },
  careerType: {
    type: String,
    default: null
  },
  office: {
    type: String,
    default: null
  },
  withLimit: {
    type: Boolean,
    default: true
  },
  sortByLatest: {
    type: Boolean,
    default: true
  }
})

const showAllJobs = ref()
const endOfPage = ref(null)
let observer = null
const { locale } = useI18n()
const emit = defineEmits(['filter'])
const { jobs, getGreenhouseJobsForCareerType, getGreenhouseJobsForCity, getGreenhouseJobsData } = useGreenHouse()

const isPending = ref(true)
const localJobs = ref({
  items: [],
  totalAmount: 0
})

onMounted(async () => {
  isPending.value = true
  const jobs = await retrieveWidgetJobs({ sortByLatest: props.sortByLatest, withLimit: props.withLimit })
  localJobs.value.items = jobs.items
  localJobs.value.totalAmount = jobs.totalAmount

  // Give a little bit of time for the filtering to take effect
  setTimeout(() => {
    isPending.value = false
  }, 200)
})

const labels = computed(() => {
  return localeGlobalContentData.value?.careerLabels || {}
})

// const isPending = computed(() => status.value === 'pending')

const baseVacanciesUrl = computed(() => localeGlobalContentData.value?.vacanciesLink?.url || '/vacancies')
const vacanciesUrl = computed(() => {
  let queryParams = `?locale=${locale.value}`
  if (props.careerType) {
    queryParams += `${getQueryParamsForCareerType(props.careerType)}`
  } else if (props.office) {
    queryParams += `${getQueryParamsForCity(props.office)}`
  }
  return baseVacanciesUrl.value + queryParams
})

const retrieveWidgetJobs = async ({ sortByLatest, withLimit }) => {
  if (props.items) {
    return {
      items: props.items,
      totalAmount: props.items.length
    }
  }

  if (props.office) {
    const result = await getGreenhouseJobsForCity(props.office, { sortByLatest, withLimit })
    return result
  }

  if (props.careerType) {
    const result = await getGreenhouseJobsForCareerType(props.careerType, { sortByLatest, withLimit })
    return result
  }

  const result = await getGreenhouseJobsData({ sortByLatest, withLimit })
  return result
}

const filteredVacanciesLinks = computed(() => {
  const jobsToFilter = props.items || localJobs.value.items || jobs.value
  const items = showAllJobs.value ? jobsToFilter : jobsToFilter.slice(0, Math.min(JOBS_AMOUNT, jobsToFilter.length))
  return items.map((item) => {
    return {
      job: {
        title: item.title,
        url: baseVacanciesUrl.value + '/' + item.slug + `?locale=${locale.value}`
      },
      department: {
        title: item.departmentsLabel,
        url: props.asWidget ? mapItemUrl(item, 'TEAM') : null,
        value: mapItemEvent(item, 'TEAM')
      },
      office: {
        title: item.officesLabel,
        url: props.asWidget ? mapItemUrl(item, 'LOCATION') : null,
        value: mapItemEvent(item, 'LOCATION')
      }
    }
  })
})

const careerHeadline = computed(() => {
  const snakeCaseCareerType = props.careerType?.charAt(0).toUpperCase() + props.careerType?.slice(1).toLowerCase()
  const careerTypeLabel = labels.value[`careerType${snakeCaseCareerType}`]

  return labels.value.startYourSpecificCareer && careerTypeLabel
    ? labels.value.startYourSpecificCareer.replace('{careerType}', careerTypeLabel)
    : labels.value.startYourCareer
})

onMounted(() => {
  initObserver()
})

onBeforeUnmount(() => {
  if (observer) {
    observer.disconnect()
  }
})

watch(jobs, () => {
  showAllJobs.value = false
  initObserver()
})

const initObserver = async () => {
  await nextTick()
  if (process.server) {
    return
  }

  if (props.asWidget) {
    showAllJobs.value = false
    return
  }

  observer = new IntersectionObserver((entries, _observer) => {
    if (entries.some(e => e.isIntersecting)) {
      showAllJobs.value = true
      _observer.disconnect()
    }
  })

  if (endOfPage.value) {
    observer.observe(endOfPage.value, { offset: 600 })
  }
}

const handleItemClicked = ({ value }) => {
  emit('filter', value)
}

const mapItemEvent = (item, type) => {
  const filter = JobFilter[type]
  const values = item[filter?.ghParam]
    .map(val => val.id)
  return {
    name: filter.name,
    value: values
  }
}
const mapItemUrl = (item, type) => {
  const filter = JobFilter[type]
  const values = item[filter?.ghParam]
    .map(val =>
      `${filter.query}=${encodeURIComponent(val.name)}`
    )
  return `${baseVacanciesUrl.value}?${values}`
}
</script>
